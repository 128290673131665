import { Button, ButtonVariantEnum, Checkbox, LogoLoadingIndicator, TextInput } from '@Wonder-Cave/ui';
import { CampaignTypeEnum } from '@shared/enums/campaign-type-enum';
import { GRClientProvider, GREnvironment, IChangeProviderForTypeRequest, IHttpResponse, ISearchClientsRecord } from '@shared/models';
import { FastField, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { axiosGet, axiosPost, axiosPut, getQueryParamString } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import useProvidersForClient from '../../hooks/useProvidersForClient';
import useTenants from '../../hooks/useTenants';
import { IDropdownValue } from '../shared/Form/Dropdown';
import GenericDropdown from '../shared/Form/Dropdowns/GenericDropdown';
import { ErrorMessage } from '../shared/Form/ErrorMessage';
import ClickerGroupDropdown from './Dropdowns/ClickerGroupDropdown';
import { IClientEditForm } from './types';

export const EditClient = () => {
  const { clientId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [client, setClient] = useState<ISearchClientsRecord>();
  const [campaignTypes, setCampaignTypes] = useState<IDropdownValue[]>([]);

  const [{ data: tenants, loading: tenantsLoading }] = useTenants();
  const [{ data: providerData, loading: providerLoading, error: providerError }, fetchProviders] = useProvidersForClient(client?.environment, clientId);

  const mapProviders = (providers: GRClientProvider[]) => {
    const mappedProviders: Map<CampaignTypeEnum, IDropdownValue[]> = new Map<CampaignTypeEnum, IDropdownValue[]>();
    let universalProviders: IDropdownValue[] = [];
    providers?.forEach((provider) => {
      if (mappedProviders.has(provider.campaignType)) {
        const existingProvider = mappedProviders.get(provider.campaignType);
        const arr: IDropdownValue[] = [...existingProvider!, { label: provider.name, value: provider.id, additionalData: provider.campaignType }];
        mappedProviders.set(provider.campaignType, arr);
      } else {
        mappedProviders.set(provider.campaignType, [{ label: provider.name, value: provider.id, additionalData: provider.campaignType }]);
      }
    });
    if (mappedProviders.size === Object.keys(CampaignTypeEnum).length) {
      // We have at least one universal provider
      universalProviders = providers.filter((obj1, i, arr) => {
        return arr.findIndex(obj2 => (obj2.id === obj1.id)) === i;
      }).map((pft) => ({ label: pft.name, value: pft.id }));
    }
    return { mappedProviders, universalProviders };
  };
  const { mappedProviders, universalProviders } = mapProviders(providerData ?? []);
  const getClient = async () => {
    setLoading(true);
    try {
      const response = await axiosGet<ISearchClientsRecord>(`/clients/${clientId}?${getQueryParamString({ environment: location?.state?.environment })}`);
      if (response.status === 200) {
        setClient(response?.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  async function updateClient(form: IClientEditForm): Promise<void> {
    setSaveLoading(true);
    try {
      const response = await axiosPut<IHttpResponse<any>>(`/clients/${clientId}`, {
        ...form,
        environment: client?.environment
      });
      if (response?.status === 200 && campaignTypes.length > 0) {
        // Save good, execute provider change if we have one
        if (form.selectProviderByCampaignType) {
          const changeResponses = await Promise.all(campaignTypes.map(ct => {
            const request: IChangeProviderForTypeRequest = {
              clientId: clientId!,
              providerId: ct.value,
              campaignType: ct.additionalData,
              environment: client?.environment ?? ''
            };
            return axiosPost(`/clients/providers-change-for-type`, request);
          }));
          if (changeResponses.every(r => r.status === 200)) {
            addNotification({ content: `Successfully executed provider swap for campaign types: ${campaignTypes.map(ct => ct.additionalData).join(', ')}` });
          }
        } else if (form.providerId) {
          const changeResponses = await Promise.all(Object.values(CampaignTypeEnum).map(ct => {
            const request: IChangeProviderForTypeRequest = {
              clientId: clientId!,
              providerId: form.providerId!,
              campaignType: ct,
              environment: client?.environment ?? ''
            };
            return axiosPost('/clients/providers-change-for-type', request);
          }));
          if (changeResponses.every(r => r.status === 200)) {
            addNotification({ content: 'Successfully executed provider swap for all campaign types' });
          }
        }
      }
      navigate('/clients');
    } catch (error: any) {
      error.error = error.response.data.error;
      throw error;
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      getClient();
    }
  }, []);

  useEffect(() => {
    if (clientId && client?.environment) {
      fetchProviders();
    }
  }, [clientId, client?.environment]);

  const schema: yup.SchemaOf<IClientEditForm> = yup.object().shape({
    clientName: yup.string().required('Required'),
    senderIdentifier: yup.string().required('Required'),
    tenantId: yup.string().required('Required'),
    providerId: yup.string().optional().nullable(),
    externalId: yup.string(),
    selectProviderByCampaignType: yup.bool().required(),
    messageFlow: yup.string().url('Call To Action must be a valid URL').required('Required'),
    defaultClickerGroupId: yup.string().nullable(),
    persuasionProviderId: yup.string(),
    gotvProviderId: yup.string(),
    fundraisingProviderId: yup.string(),
    listBuildingProviderId: yup.string(),
    privacyPolicyUrl: yup.string().url('Privacy Policy must be a valid URL').max(255, 'Privacy Policy must be at most 255 characters').notRequired(),
    termsAndConditionsUrl: yup.string().url('Terms and Conditions must be a valid URL').max(255, 'Terms and Conditions must be at most 255 characters').notRequired()
  });
  return <>
    {loading && <div className='fixed top-0 bottom-0 z-10 flex w-full h-full bg-dark-gray' style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
      <div className='my-auto mr-auto ml-[calc(50%-130px)] z-20'><LogoLoadingIndicator /></div>
    </div>}
    <div className='px-28'><div className='pb-16'>
      <div className='flex justify-between'>
        <h1 className='mb-2'>{client?.name}</h1>
        <div>
          <Button formId='edit-form' type='submit' className='mr-4' isLoading={saveLoading} variant={ButtonVariantEnum.PRIMARY}>SAVE CHANGES</Button>
          <Button onClick={() => navigate('/clients')} variant={ButtonVariantEnum.SECONDARY}>BACK</Button>
        </div>
      </div>
      <div className='flex mb-8 text-wc-blue'><h3 className='mr-2'>{'Brand ID: ' + client?.tcrBrandId}</h3> | <h3 className='ml-2'>{'Universal EIN: ' + client?.tcrBrand?.universalEin}</h3></div>
    </div>
      <Formik
        onSubmit={(values) => updateClient(values)}
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          clientName: client?.name,
          senderIdentifier: client?.senderIdentifier,
          providerId: client?.providerId,
          externalId: client?.externalId,
          tenantId: client?.tenantId,
          defaultClickerGroupId: client?.defaultClickerGroupId,
          selectProviderByCampaignType: new Set(client?.providers?.map(p => p.id)).size !== 1,
          messageFlow: client?.messageFlow,
          persuasionProviderId: client?.providers?.find(p => p.campaignType === CampaignTypeEnum.PERSUASION)?.id,
          gotvProviderId: client?.providers?.find(p => p.campaignType === CampaignTypeEnum.GOTV)?.id,
          fundraisingProviderId: client?.providers?.find(p => p.campaignType === CampaignTypeEnum.FUNDRAISING)?.id,
          listBuildingProviderId: client?.providers?.find(p => p.campaignType === CampaignTypeEnum.LIST_BUILDING)?.id,
          termsAndConditionsUrl: client?.termsAndConditionsUrl,
          privacyPolicyUrl: client?.privacyPolicyUrl
        } as IClientEditForm}>
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, dirty, setFieldError, setErrors }) => (
          <form id="edit-form" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mr-8 space-y-8 info-container">
              <h2 className='text-black basis-1/2'>Client Information</h2>
              <div style={{ marginTop: 0 }} className='-ml-1'></div>
              <FastField
                component={TextInput}
                id="clientName"
                name="clientName"
                label="Client Name"
                placeholder="Enter client"
                value={values.clientName}
                error={touched.clientName ? errors.clientName : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ClickerGroupDropdown
                value={values.defaultClickerGroupId}
                onChange={(newValue) => {
                  setFieldValue('defaultClickerGroupId', newValue?.value);
                  setFieldTouched('defaultClickerGroupId');
                }}
                onBlur={() => {
                  setFieldTouched('defaultClickerGroupId');
                }}
                showError={touched.defaultClickerGroupId}
                errorMessage={errors.defaultClickerGroupId}
                environment={client?.environment ?? GREnvironment.DEV}
                orgId={client?.tenant?.authId ?? ''}
              />
              <FastField
                component={TextInput}
                id="senderIdentifier"
                name="senderIdentifier"
                label="Sender Identifier"
                placeholder="Enter Sender Identifier"
                value={values.senderIdentifier}
                error={touched.senderIdentifier ? errors.senderIdentifier : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FastField
                component={TextInput}
                id="externalId"
                name="externalId"
                label="External ID"
                placeholder="Enter External ID"
                value={values.externalId}
                error={touched.externalId ? errors.externalId : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FastField
                component={TextInput}
                id="messageFlow"
                name="messageFlow"
                label="CALL TO ACTION URL"
                placeholder="Enter URL"
                value={values.messageFlow}
                error={touched.messageFlow ? errors.messageFlow : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FastField
                component={TextInput}
                id="privacyPolicyUrl"
                name="privacyPolicyUrl"
                label="Privacy Policy URL"
                placeholder="Enter URL"
                value={values.privacyPolicyUrl}
                error={touched.privacyPolicyUrl ? errors.privacyPolicyUrl : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FastField
                component={TextInput}
                id="termsAndConditionsUrl"
                name="termsAndConditionsUrl"
                label="Terms And Conditions URL"
                placeholder="Enter URL"
                value={values.termsAndConditionsUrl}
                error={touched.termsAndConditionsUrl ? errors.termsAndConditionsUrl : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <GenericDropdown
                value={universalProviders.find((provider) => provider.value === values.providerId)}
                label='PROVIDER'
                options={universalProviders}
                loading={providerLoading}
                placeholder={(!loading && !providerLoading && universalProviders.length <= 0) ? 'No results' : 'Select a provider'}
                disabled={values.selectProviderByCampaignType || (!loading && universalProviders.length <= 0)}
                onChange={(newValue) => {
                  setFieldValue('providerId', newValue?.value);
                  setFieldTouched('providerId');
                }}
                onBlur={() => {
                  setFieldTouched('providerId');
                }}
                showError={touched.providerId}
                errorMessage={errors.providerId}
              />
              <div><Checkbox
                id="selectProviderByCampaignType"
                label='Select provider by campaign type'
                checked={values.selectProviderByCampaignType}
                onChange={(e) => {
                  setFieldValue('selectProviderByCampaignType', e.target.checked);
                }}
                disabled={mappedProviders.size <= 0}
              />
                <ErrorMessage show={!loading && !providerLoading && mappedProviders.size <= 0} message='No providers found for client' />
              </div>
            </div>
            {values?.selectProviderByCampaignType && <div className='flex flex-wrap mt-12 -mr-8 space-y-12'>
              <h2 className='text-black basis-1/2'>Provider by Campaign Type</h2>
              <div className='flex flex-wrap w-full'>
                <div className='pr-8 mb-10 basis-1/2'>
                  <GenericDropdown
                    value={mappedProviders.get(CampaignTypeEnum.FUNDRAISING)?.find((provider) => provider.value === values.fundraisingProviderId)}
                    label='FUNDRAISING'
                    options={mappedProviders.get(CampaignTypeEnum.FUNDRAISING) ?? []}
                    disabled={(mappedProviders.get(CampaignTypeEnum.FUNDRAISING) ?? []).length <= 0}
                    loading={providerLoading}
                    onChange={(newValue) => {
                      setFieldValue('fundraisingProviderId', newValue?.value);
                      setFieldTouched('fundraisingProviderId');
                      setCampaignTypes(prevState => ([
                        ...prevState,
                        newValue!
                      ]));
                    }}
                    onBlur={() => {
                      setFieldTouched('fundraisingProviderId');
                    }}
                    showError={touched.fundraisingProviderId}
                    errorMessage={errors.fundraisingProviderId}
                  />
                </div>
                <div className='basis-1/2'>
                  <GenericDropdown
                    value={mappedProviders.get(CampaignTypeEnum.GOTV)?.find((provider) => provider.value === values.gotvProviderId)}
                    label='GOTV'
                    options={mappedProviders.get(CampaignTypeEnum.GOTV) ?? []}
                    loading={providerLoading}
                    onChange={(newValue) => {
                      setFieldValue('gotvProviderId', newValue?.value);
                      setFieldTouched('gotvProviderId');
                      setCampaignTypes(prevState => ([
                        ...prevState,
                        newValue!
                      ]));
                    }}
                    disabled={(mappedProviders.get(CampaignTypeEnum.GOTV) ?? []).length <= 0}
                    onBlur={() => {
                      setFieldTouched('gotvProviderId');
                    }}
                    showError={touched.gotvProviderId}
                    errorMessage={errors.gotvProviderId}
                  />
                </div>
                <div className='pr-8 basis-1/2'>
                  <GenericDropdown
                    value={mappedProviders.get(CampaignTypeEnum.PERSUASION)?.find((provider) => provider.value === values.persuasionProviderId)}
                    label='PERSUASION'
                    options={mappedProviders.get(CampaignTypeEnum.PERSUASION) ?? []}
                    loading={providerLoading}
                    onChange={(newValue) => {
                      setFieldValue('persuasionProviderId', newValue?.value);
                      setFieldTouched('persuasionProviderId');
                      setCampaignTypes(prevState => ([
                        ...prevState,
                        newValue!
                      ]));
                    }}
                    disabled={(mappedProviders.get(CampaignTypeEnum.PERSUASION) ?? []).length <= 0}
                    onBlur={() => {
                      setFieldTouched('persuasionProviderId');
                    }}
                    showError={touched.persuasionProviderId}
                    errorMessage={errors.persuasionProviderId}
                  />
                </div>
                <div className='basis-1/2'>
                  <GenericDropdown
                    value={mappedProviders.get(CampaignTypeEnum.LIST_BUILDING)?.find((provider) => provider.value === values.listBuildingProviderId)}
                    label='LIST BUILDING'
                    options={mappedProviders.get(CampaignTypeEnum.LIST_BUILDING) ?? []}
                    loading={providerLoading}
                    disabled={(mappedProviders.get(CampaignTypeEnum.LIST_BUILDING) ?? []).length <= 0}
                    onChange={(newValue) => {
                      setFieldValue('listBuildingProviderId', newValue?.value);
                      setFieldTouched('listBuildingProviderId');
                      setCampaignTypes(prevState => ([
                        ...prevState,
                        newValue!
                      ]));
                    }}
                    onBlur={() => {
                      setFieldTouched('listBuildingProviderId');
                    }}
                    showError={touched.listBuildingProviderId}
                    errorMessage={errors.listBuildingProviderId}
                  />
                </div>
              </div>
            </div>}
          </form>
        )}
      </Formik>
    </div></>;
};