import { Button, ButtonVariantEnum, Radio, RadioCheckedTurquoise, TextInput, ToggleSwitch, ToggleSwitchLabelLocationEnum } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { TcrRegistryBrand } from '@shared/models';
import { AltBusinessIdType } from '@shared/models/domain/clients/alt-business-id-type.enum';
import { axiosGet } from 'apps/gsd-portal/src/authAxios';
import { FastField, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import * as yup from 'yup';
import { sortByLabel } from '../../../providers/utility.provider';
import GenericDropdown from '../../shared/Form/Dropdowns/GenericDropdown';
import ClickerGroupDropdown from '../Dropdowns/ClickerGroupDropdown';
import TcrEntityTypeDropdown from '../Dropdowns/TcrEntityTypeDropdown';
import { ICompanyInfoForm, countryOptions, usCountryOption } from '../types';

interface ICompanyInfoProps {
  onSubmit: (data: ICompanyInfoForm) => void;
  onBack: () => void;
  tenantOptions: IDropdownValue[];
  existingValues: ICompanyInfoForm;
}

const ClientCompanyInfoForm = ({ onSubmit, onBack, tenantOptions, existingValues }: ICompanyInfoProps) => {

  const [showCompanyInfo, setShowCompanyInfo] = useState(!!existingValues.tenantId);
  const [loadingBrand, setLoadingBrand] = useState(false);

  const altBusinessIdTypeOptions: IDropdownValue[] = sortByLabel(Object.entries(AltBusinessIdType).map(entry => ({
    label: entry[0],
    value: entry[0]
  })));

  const getValidAreaCode = (val?: any) => {
    // Get first 3 numbers
    const value = String(val)?.replace(/\D/g, '')?.substring(0, 3) ?? '';
    return value?.startsWith('0') ? undefined : value;
  };

  const schema: yup.SchemaOf<ICompanyInfoForm> = yup.object().shape({
    companyName: yup.string().required('Required'),
    brandName: yup.string().required('Required'),
    clientName: yup.string().required('Required'),
    isActive: yup.boolean().required('Required'),
    senderIdentifier: yup.string().required('Required'),
    externalId: yup.string(),
    messageFlow: yup.string().url('Call To Action must be a valid URL').required('Required'),
    twoWayEnabled: yup.boolean().required('Required'),
    defaultClickerGroupId: yup.string().when('tenantId', {
      is: undefined,
      then: yup.string().required('Select a tenant'),
      otherwise: yup.string().test('Required', 'Required', (value) => isEmpty(value) || (!!value && value?.length > 0))
    }),
    tenantId: yup.string().required('Required'),
    orgId: yup.string().required('Required'),
    tcrEntityType: yup.string().required('Required'),
    registrationCountryCode: yup.object()
      .shape({
        label: yup.string().defined('Required'),
        value: yup.mixed().defined('Required'),
      }).required('Required'),
    taxId: yup.string().required('Required'),
    taxIdCountryCode: yup.object()
      .shape({
        label: yup.string().defined('Required'),
        value: yup.mixed().defined('Required'),
      }).required('Required'),
    altBusinessIdType: yup.string().required('Required'),
    altBusinessId: yup.string(),
    tcrBrandId: yup.string(),
    areaCode: yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .length(3, 'Must be exactly 3 digits'),
    privacyPolicyUrl: yup.string().url('Privacy Policy must be a valid URL').max(255, 'Privacy Policy must be at most 255 characters').notRequired(),
    termsAndConditionsUrl: yup.string().url('Terms and Conditions must be a valid URL').max(255, 'Terms and Conditions must be at most 255 characters').notRequired()
  });

  return (
    <Formik
      onSubmit={(values) => onSubmit(values)}
      validationSchema={schema}
      initialValues={!!existingValues ? existingValues : {
        clientName: '',
        companyName: '',
        isActive: true,
        senderIdentifier: '',
        externalId: '',
        messageFlow: '',
        twoWayEnabled: false,
        brandName: '',
        defaultClickerGroupId: '',
        tcrEntityType: undefined,
        registrationCountryCode: usCountryOption,
        taxId: '',
        tenantId: undefined,
        orgId: undefined,
        taxIdCountryCode: usCountryOption,
        altBusinessIdType: undefined,
        altBusinessId: undefined,
        tcrBrandId: undefined,
        areaCode: undefined,
        privacyPolicyUrl: undefined,
        termsAndConditionsUrl: undefined
      }}>
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, dirty, setFieldError, setErrors }) => (
        <form id="info-form" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mr-8 space-y-8 info-container">
            <h2 className='text-black basis-1/2'>Client Information</h2>
            <div style={{ marginTop: 0 }} className='-ml-1'><ToggleSwitch labelLocation={ToggleSwitchLabelLocationEnum.LEFT} label='Select Status: ' labelStyle='text-bold' checkedLabel checked={values.isActive} checkedColor='bg-turquoise' onChange={() => setFieldValue('isActive', !values.isActive)} /></div>
            <FastField
              component={TextInput}
              id="clientName"
              name="clientName"
              label="Client Name"
              placeholder="Enter client"
              value={values.clientName}
              error={touched.clientName ? errors.clientName : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <GenericDropdown
              label="Tenant"
              placeholder="Select a tenant"
              options={tenantOptions}
              value={tenantOptions.find(t => t.value === values.tenantId)}
              onChange={(newValue) => {
                setFieldValue('tenantId', newValue?.value);
                setFieldValue('orgId', newValue?.additionalData?.orgId);
                setFieldTouched('tenantId');
              }}
              onBlur={() => {
                setFieldTouched('tenantId');
              }}
              showError={!!(touched.tenantId && errors.tenantId)}
              errorMessage={(errors?.tenantId as any)?.value as string}
            />
            <ClickerGroupDropdown
              value={values?.defaultClickerGroupId ?? undefined}
              onChange={(newValue) => {
                setFieldValue('defaultClickerGroupId', newValue?.value ?? '');
                setFieldTouched('defaultClickerGroupId');
              }}
              onBlur={() => {
                setFieldTouched('defaultClickerGroupId');
              }}
              showError={touched.defaultClickerGroupId}
              errorMessage={errors.defaultClickerGroupId}
              orgId={tenantOptions.find(t => t.value === values.tenantId)?.additionalData?.orgId}
              environment={tenantOptions.find(t => t.value === values.tenantId)?.additionalData?.environment}
              defaultGroupName="None"
              includeNone
            />
            <FastField
              component={TextInput}
              id="senderIdentifier"
              name="senderIdentifier"
              label="Sender Identifier"
              placeholder="Enter Sender Identifier"
              value={values.senderIdentifier}
              error={touched.senderIdentifier ? errors.senderIdentifier : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FastField
              component={TextInput}
              id="externalId"
              name="externalId"
              label="External ID"
              placeholder="Enter External ID"
              value={values.externalId}
              error={touched.externalId ? errors.externalId : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FastField
              component={TextInput}
              id="messageFlow"
              name="messageFlow"
              label="CALL TO ACTION URL"
              placeholder="Enter URL"
              value={values.messageFlow}
              error={touched.messageFlow ? errors.messageFlow : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FastField
              component={TextInput}
              id="privacyPolicyUrl"
              name="privacyPolicyUrl"
              label="Privacy Policy URL (optional)"
              placeholder="Enter URL"
              value={values.privacyPolicyUrl}
              error={touched.privacyPolicyUrl ? errors.privacyPolicyUrl : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FastField
              component={TextInput}
              id="termsAndConditionsUrl"
              name="termsAndConditionsUrl"
              label="Terms And Conditions URL (optional)"
              placeholder="Enter URL"
              value={values.termsAndConditionsUrl}
              error={touched.termsAndConditionsUrl ? errors.termsAndConditionsUrl : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Radio
              label="Would you like to set up conversation templates?"
              value={values.twoWayEnabled}
              grow
              error={errors.twoWayEnabled}
              options={[{
                label: 'Yes',
                value: true
              }, {
                label: 'No',
                value: false
              }]}
              checkedIcon={<RadioCheckedTurquoise />}
              onChange={(newValue) => {
                setFieldValue('twoWayEnabled', newValue);
                setFieldTouched('twoWayEnabled');
              }}
            />

            <FastField
              component={TextInput}
              id="areaCode"
              name="areaCode"
              label="Area Code (optional)"
              placeholder="Enter area code"
              value={values.areaCode}
              error={touched.areaCode ? errors.areaCode : ''}
              onChange={(val) => {
                setFieldTouched('areaCode');
                setFieldValue('areaCode', getValidAreaCode(val?.target?.value));
              }}
              onBlur={handleBlur}
            />

            <FastField
              component={TextInput}
              id="tcrBrandId"
              name="tcrBrandId"
              label="TCR Brand ID"
              placeholder="Enter TCR Brand ID"
              value={values.tcrBrandId}
              error={touched.tcrBrandId ? errors.tcrBrandId : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {!showCompanyInfo &&
              <div className="flex items-center">
                <Button
                  type="button"
                  variant={values.tcrBrandId ? ButtonVariantEnum.PRIMARY : ButtonVariantEnum.TERTIARY}
                  isLoading={loadingBrand}
                  onClick={async () => {
                    try {
                      setLoadingBrand(true);

                      if (values.tcrBrandId?.trim()) {

                        const brand = await axiosGet<TcrRegistryBrand>(`/brands?id=${values.tcrBrandId.trim()}`).then(resp => resp.data);

                        setFieldValue('companyName', brand.companyName);
                        setFieldValue('brandName', brand.displayName);
                        setFieldValue('tcrEntityType', brand.entityType);
                        setFieldValue('taxId', brand.ein);
                        setFieldValue('altBusinessId', brand.altBusinessId);
                        setFieldValue('altBusinessIdType', brand.altBusinessIdType);

                        const taxIdCountryCodeOption = countryOptions.find(op => op.value === brand.einIssuingCountry);
                        if (taxIdCountryCodeOption) {
                          setFieldValue('taxIdCountryCode', taxIdCountryCodeOption);
                        }

                        return await onSubmit(values);
                      }

                      setShowCompanyInfo(true);
                    } catch (e) {
                      setFieldError('tcrBrandId', `Unable to retrieve brand for this ID`);
                      setFieldValue('tcrBrandId', undefined);
                      console.error('Error retrieving brand:', e);
                    } finally {
                      setLoadingBrand(false);
                    }
                  }}>
                  {values.tcrBrandId ? 'SAVE' : 'SUBMIT'}
                </Button>
              </div>
            }
          </div>
          {showCompanyInfo &&
            <>
              <hr className="h-[2px] mt-8 bg-gray-200 border-0 mb-14 dark:bg-gray-700" />

              <div className="flex flex-wrap -mr-8 space-y-8 info-container">
                <h2 className="text-black basis-full">Company Information</h2>
                <FastField
                  component={TextInput}
                  id="companyName"
                  name="companyName"
                  label="Legal Company Name"
                  placeholder="Enter Legal Company Name"
                  value={values.companyName}
                  error={touched.companyName ? errors.companyName : ''}
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('companyName', true);
                    if (!values.brandName) {
                      setFieldValue('brandName', values.companyName);
                    }
                  }}
                />
                <FastField
                  component={TextInput}
                  id="brandName"
                  name="brandName"
                  label="DBA/Brand Name (If different from legal name)"
                  placeholder="Enter DBA or Brand Name"
                  value={values.brandName}
                  error={touched.brandName ? errors.brandName : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* ************************************** */}

                <TcrEntityTypeDropdown
                  value={values.tcrEntityType ?? ''}
                  onChange={(newValue) => {
                    setFieldValue('tcrEntityType', newValue?.value);
                    setFieldTouched('tcrEntityType');
                  }}
                  onBlur={() => {
                    setFieldTouched('tcrEntityType');
                  }}
                  showError={!!(touched.tcrEntityType && errors.tcrEntityType)}
                  errorMessage={(errors?.tcrEntityType as any)?.value as string}
                />

                <GenericDropdown
                  label="Country of Registration"
                  placeholder="Select a country"
                  options={countryOptions}
                  value={values.registrationCountryCode}
                  onChange={(newValue) => {
                    setFieldValue('registrationCountryCode', newValue);
                    setFieldTouched('registrationCountryCode');
                  }}
                  onBlur={() => {
                    setFieldTouched('registrationCountryCode');
                  }}
                  showError={!!(touched.registrationCountryCode && errors.registrationCountryCode)}
                  errorMessage={(errors?.registrationCountryCode as any)?.value as string}
                  disabled
                />

                {/* ************************************** */}
                <FastField
                  component={TextInput}
                  id="taxId"
                  name="taxId"
                  label="Tax Number/ID/EIN"
                  placeholder="Enter Tax Number/ID/EIN"
                  value={values.taxId}
                  error={touched.taxId ? errors.taxId : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <GenericDropdown
                  label="Tax Number/ID/EIN Issuing Country"
                  placeholder="Select a country"
                  options={countryOptions}
                  value={values.taxIdCountryCode}
                  onChange={(newValue) => {
                    setFieldValue('taxIdCountryCode', newValue);
                    setFieldTouched('taxIdCountryCode');
                  }}
                  onBlur={() => {
                    setFieldTouched('taxIdCountryCode');
                  }}
                  showError={!!(touched.taxIdCountryCode && errors.taxIdCountryCode)}
                  errorMessage={(errors?.taxIdCountryCode as any)?.value as string}
                  disabled
                />
                {/* ************************************* */}

                <GenericDropdown
                  label="Alternative ID Type (DUNS/GIIN/LEI)"
                  placeholder="Select alternative id type"
                  options={altBusinessIdTypeOptions}
                  value={altBusinessIdTypeOptions.find(abito => abito.value === values.altBusinessIdType)}
                  onChange={(newValue) => {
                    setFieldValue('altBusinessIdType', newValue?.value);
                    setFieldTouched('altBusinessIdType');
                  }}
                  onBlur={() => {
                    setFieldTouched('altBusinessIdType');
                  }}
                  showError={!!(touched.altBusinessIdType && errors.altBusinessIdType)}
                  errorMessage={(errors?.altBusinessIdType as any)?.value as string}
                />

                <FastField
                  component={TextInput}
                  id="altBusinessId"
                  name="altBusinessId"
                  label="DUNS/GIIN/LEI Value"
                  placeholder="Enter DUNS/GIIN/LEI Number"
                  value={values.altBusinessId}
                  error={touched.altBusinessId ? errors.altBusinessId : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </>
          }
          {showCompanyInfo &&
            <div className="flex justify-end w-full mt-8">
              <Button
                variant={ButtonVariantEnum.SECONDARY}
                className="mr-4"
                onClick={onBack}
              >
                BACK
              </Button>
              <Button formId="info-form" type="submit" disabled={!values.clientName || !isValid}>
                NEXT
              </Button>
            </div>
          }
        </form>
      )}
    </Formik>
  );
};

export default ClientCompanyInfoForm;
