import { Static, ToggleSwitch } from '@Wonder-Cave/ui';
import { ISearchClientsRecord } from '@shared/models';
import moment from 'moment';

interface ClientInformationProps {
  client?: ISearchClientsRecord;
  loading: boolean;
}

export const ClientInformation = ({ client, loading }: ClientInformationProps) => {

  return <div className='flex flex-col px-20'>
    <div className='flex flex-col'>
      <div className='flex flex-wrap items-center justify-between'>
        <h2 className='text-black'>Client Information</h2>
        <ToggleSwitch label='Conversation Templates' checkedColor='bg-turquoise cursor-default' uncheckedColor='bg-light-gray cursor-default' checked={client?.twoWayEnabled ?? false} onChange={() => { }} />
      </div>
      <div className='flex flex-wrap static-box'>
        <Static shimmer={10} title='CLIENT NAME'>{client?.name}</Static>
        <Static shimmer={10} title='PROVIDER'>{client?.providerName}</Static>
        <Static shimmer={10} title='DEFAULT AGENT GROUP'>{!client?.defaultClickerGroup?.name ? !loading ? 'None' : undefined : client?.defaultClickerGroup?.name}</Static>
        <Static shimmer={10} title='SENDER IDENTIFIER'>{!client?.senderIdentifier ? !loading ? 'N/A' : undefined : client?.senderIdentifier}</Static>
        <Static shimmer={10} title='TCR BRAND ID'>{!client?.tcrBrandId ? !loading ? 'N/A' : undefined : client?.tcrBrandId}</Static>
        <Static shimmer={10} title='EXTERNAL ID'>{client?.externalId}</Static>
        <Static shimmer={10} title='CALL TO ACTION URL'>{!client?.messageFlow ? !loading ? 'N/A' : undefined : client?.messageFlow}</Static>
        <Static shimmer={10} title='Privacy Policy URL'>{!client?.privacyPolicyUrl ? !loading ? 'N/A' : undefined : client?.privacyPolicyUrl}</Static>
        <Static shimmer={10} title='Terms and Conditions URL'>{!client?.termsAndConditionsUrl ? !loading ? 'N/A' : undefined : client?.termsAndConditionsUrl}</Static>
      </div>
    </div>
    <div className='mt-8'>
      <h2 className='text-black'>Company Information</h2>
      <div className='flex flex-wrap static-box'>
        <Static shimmer={10} title='BRAND NAME OR DBA'>{client?.tcrBrand?.displayName}</Static>
        <Static shimmer={10} title='ENTITY TYPE'>{client?.tcrBrand?.entityType}</Static>
        <Static shimmer={10} title='EIN'>{!client?.tcrBrand?.ein ? !loading ? 'N/A' : undefined : client?.tcrBrand?.ein}</Static>
        <Static shimmer={10} title='EIN ISSUING COUNTRY'>{!client?.tcrBrand?.einIssuingCountry ? !loading ? 'N/A' : undefined : client?.tcrBrand?.einIssuingCountry}</Static>
        <Static shimmer={10} title='VERTICAL'>{!client?.tcrBrand?.vertical ? !loading ? 'N/A' : undefined : client?.tcrBrand?.vertical}</Static>
        <Static shimmer={10} title='FIRST NAME'>{!client?.tcrBrand?.firstName ? !loading ? 'N/A' : undefined : client?.tcrBrand?.firstName}</Static>
        <Static shimmer={10} title='LAST NAME'>{!client?.tcrBrand?.lastName ? !loading ? 'N/A' : undefined : client?.tcrBrand?.lastName}</Static>
        <Static shimmer={10} title='REGISTERED ON'>{client?.tcrBrand?.createDate ? moment(client?.tcrBrand?.createDate).format('L') : undefined}</Static>
        <Static shimmer={10} title='WEBSITE/ONLINE PRESENCE'>{!client?.tcrBrand?.website ? !loading ? 'N/A' : undefined : client?.tcrBrand?.website}</Static>
      </div>
    </div>
    <div className='mt-8 mb-8'>
      <h2 className='text-black'>Contact Details</h2>
      <div className='flex flex-wrap static-box'>
        <Static shimmer={10} title='SUPPORT PHONE NUMBER'>{client?.tcrBrand?.phone}</Static>
        <Static shimmer={10} title='SUPPORT EMAIL ADDRESS'>{client?.tcrBrand?.email}</Static>
      </div>
    </div>
  </div>;
};